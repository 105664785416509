<template>
  <div class="home">
    <div class="topBanner relative">
      <img style=" width: 32%; margin-left: -8.2%;" src="@/assets/img/panda.png" alt="">
      <img style=" width: 14%; margin: 0 10% .8rem 5.5%;" src="@/assets/img/logo.png" alt="">
      <img style=" width: 19.6%; margin-top: .41rem;" src="@/assets/img/rabbit.png" alt="">
      <img class="btn" src="@/assets/img/button.png" @click="toContact" alt="">
    </div>
    <div class="about" id="contactUs">
      <h2>About</h2>
      <p>Coin Technology is a mobile gaming company. <br>Our objective is to combine technology and creativity to develop high-quality mobile games that will be played for years. At Coin Tech, all team members have a genuine chance to contribute to the final product and have an impact on the company.</p>
    </div>
    <div class="game">
      <div class="gameContent">
        <div>
          <h2>Shing Ace Max</h2>
          <p>Embark on an exciting open world adventure together in the world of Shining Ace Max! Together with your companions, forge unforgettable memories as you unravel the secrets of this vibrant world.</p>
          <a href="https://play.google.com/store/apps/details?id=com.co.sev.max&hl=en&gl=hk" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/google.png" class="google" alt=""></a>
        </div>
        <img class="gameLogo" src="@/assets/img/game2.png" alt="">
      </div>
    </div>
    <div class="game">
      <div class="gameContent">
        <img class="gameLogo" src="@/assets/img/game1.png" alt="">
        <div>
          <h2>NAME OF GAME</h2>
          <p>We have a diverse portfolio of games, different categories ranging from 3D Strategy RPG, Sports games, and Instant Casual games. Amusement Planet aims to deliver high-quality Online Entertainment with the Best of Digital Technology.</p>
          <img class="google" src="@/assets/img/google.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {

    }
  },
  components: {
  },
  computed: {
    ...Vuex.mapGetters([
      "bannerImg",
      "headerImg",
    ])
  },
  methods: {
    toContact() {
      this.$router.push({
        name: 'home',
        query: {
          to: 'contactUs',
          t: +new Date()
        }
      })
    }
  }

}
</script>
<style lang="stylus">
.home {
  background-color: #f3f3f3;
  padding-top: .98rem;
  .topBanner {
    margin: 0 auto;
  }
  & > div {
    font-family: gilroy;
    &:nth-child(even) {
      background-color: #fff;
    }
    h2 {
      font-weight: bold;
      font-size: .5rem;
      font-family: gotham;
    }
  }
  .topBanner {
    flex( row, nowrap, center, center );
    padding: 1rem .3rem;
    img {
      height: auto;
    }
    .btn {
      position: absolute;
      width: .6rem;
      left: 50%;
      transform: translateX(-50%);
      bottom: 3vw;
      cursor: pointer;
    }
  }
  .about {
    padding: 1.3rem 0 1.5rem;
    text-align: center;
    font-size: .18rem;
    p {
      max-width: 7.46rem;
      margin: .2rem auto 0;
      line-height: .3rem;
    }
  }
  .game {
    font-size: .18rem;
    .gameContent {
      max-width: 11.4rem;
      flex(row, nowrap, space-between, center);
      margin: 0 auto;
      padding: .5rem .4rem;
      .gameLogo {
        height: 5.75rem;  
      }
      & >div {
        max-width: 4.2rem;
      }
      .google {
        margin-top: .3rem;
        cursor: pointer;
        width: 1.6rem;
      }
        
    }  
    p {
      margin: .2rem auto 0;
      line-height: .3rem;
      color: $C0;
    }
  }
}
</style>